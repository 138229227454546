<template>
  <div class="logueo">
    <div class="encabezado">
      <img src="@/assets/img/compumacc.png" alt="" />
      <h2>Reg&iacute;strate</h2>
    </div>
    <form
      name="login"
      class="login column is-12-mobile is-mobile"
      v-on:submit.prevent="procesar"
      method="POST"
      action=""
    >
      <div class="base">
        <section>
          <b-field horizontal label="Nombre" class="is-mobile">
            <b-input
              placeholder="Nombre"
              type="text"
              icon="account"
              name="nombre"
              required
            ></b-input>
          </b-field>
          <b-field horizontal label="Correo:" class="is-mobile">
            <b-input
              placeholder="Correo"
              type="email"
              icon="email"
              name="email"
              required
            >
            </b-input>
          </b-field>
          <b-field horizontal label="Contraseña:">
            <b-input
              placeholder="Contraseña"
              type="password"
              password-reveal
              id="pass"
              icon="lock"
              required
              maxlength="32"
              minlength="8"
              name="pass"
            >
            </b-input>
          </b-field>
          <b-field horizontal label="Teléfono:" class="mg-neg">
            <b-input
              placeholder="Teléfono"
              type="tel"
              minlength="10"
              maxlength="10"
              pattern="[0-9]*"
              icon="phone"
              name="telefono"
              required
            >
            </b-input>
          </b-field>
          <b-field horizontal label="Dirección:" class="mg-neg">
            <b-input
              placeholder="Dirección"
              type="text"
              icon="home"
              name="direccion"
              required
            >
            </b-input>
          </b-field>
        </section>
      </div>

      <!--  -->

      <div class="botones">
        <p>
          <b-button class="boton2" v-on:click="cerrarModal()"
            >Cancelar</b-button
          >
          <b-button class="boton" v-on:click="procesar()">Regístrar</b-button>
        </p>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: "LoginGeneral",
  data() {
    return {
      showModal: true,
    };
  },
  methods: {
    cerrarModal() {
      this.showModal = false;
      this.$emit("value", this.showModal);
    },
    procesar() {
      if (document.login.nombre.value.length == 0) {
        document.login.nombre.focus();
        return 0;
      }
      if (document.login.email.value.length == 0) {
        document.login.email.focus();
        return 0;
      }

      if (document.login.pass.value.length == 0) {
        document.login.pass.focus();
        return 0;
      }
      if (document.login.telefono.value.length == 0) {
        document.login.telefono.focus();
        return 0;
      }
      if (document.login.direccion.value.length == 0) {
        document.login.direccion.focus();
        return 0;
      }

      document.invitado.submit();
      this.cerrarModal();
      alert("Registrado!!");
    },
  },
};
</script>

<style scoped>
.logueo {
  border-radius: 10px;
  background-color: white;
  box-shadow: 0px 0px 7px #666;
  max-width: 450px;
  min-height: 500px;
  margin: 0 auto;
}
.login {
  margin-top: 10px;
  overflow: auto;
  overflow-x: hidden;
}
.encabezado {
  display: flex;
}
.encabezado img {
  margin: 10px 10px;
  padding: 5px;
  width: 30%;
}
h2 {
  width: 69%;
  margin: 0 auto;
  margin-right: 20px;
  padding: 5px 10px;
  font-size: 25px;
  color: #333;
  font-weight: 600;
  text-align: center;
  padding-top: 10%;
}
h2:after {
  display: block;
  height: 3px;
  background-color: #4590bf;
  content: "";
  width: 100px;
  margin-top: 10px;
  box-shadow: 1px 1px 3px #666;
  margin-bottom: 10px;
  margin: 0 auto;
}
.base {
  text-align: justify;
  margin: 0 auto;
  width: 95%;
}
.botones {
  text-align: right;
  margin-right: 5%;
  margin-top: 5%;
}
a {
  color: #4590bf;
  margin-bottom: 5%;
  margin-top: 5%;
  display: inline-block;
}
.boton {
  background-color: #4590bf;
  color: white;
  border: 1px solid #4590bf;
  margin-right: auto;
  border-radius: 5px;
  padding: 8px 25px;
  font-size: 16px;
  margin-bottom: 20px;
  width: 30%;
}
.boton:hover {
  background-color: #4590bf;
  color: white;
  border: 1px solid #4590bf;
  font-weight: 600;
  box-shadow: 1px 1px 10px #4590bf;
}
.boton2 {
  color: #4590bf;
  border: 1px solid #4590bf;
  background-color: white;
  margin-right: auto;
  border-radius: 5px;
  padding: 5px 25px;
  font-size: 16px;
  margin-bottom: 20px;
  margin-right: 10px;
  width: 30%;
}
.boton2:hover {
  color: #4590bf;
  border: 1px solid #4590bf;
  background-color: white;
  box-shadow: 1px 1px 10px #4590bf;
  font-weight: 600;
}
.mg-neg {
  margin-top: -20px;
}
@media screen and (max-width: 680px) {
  .boton,
  .boton2 {
    width: 45%;
    font-size: 75%;
    padding: 6px 15px;
  }
  h2 {
    font-size: 120%;
  }
  h2::after {
    width: 50%;
    margin: 0 auto;
  }
  .logueo {
    width: 350px;
    margin: 0 auto;
  }
}
</style>