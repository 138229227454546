import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/index.vue'
import ServicesView from '../views/services.vue'
import ContactView from "../views/contact.vue";
import ProductsHome from "../views/products/index.vue"
import DetailProduct from "../views/products/_id.vue"
import DetailShop from "../views/shop/index.vue"
import Pay from "../views/shop/shop.vue"
import ErrorPage from "../views/error.vue"

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/services',
    name: 'services',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    //component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
    component: ServicesView
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactView
  },
  {
    path: '/products/',
    name: 'products',
    component: ProductsHome
  },
  {
    path: '/products/:id',
    name: 'product',
    component: DetailProduct
  },
  {
    path: '/shop',
    name: 'Detail Shop',
    component: DetailShop
  },
  {
    path: '/shop/pay',
    name: 'Pay Shop',
    component: Pay
  },
  {
    path: '*',
    name: 'Error',
    component: ErrorPage
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
