<template>
  <div class="productos">
    <div class="container">
      <h2>Productos</h2>
      <div class="columns is-multiline is-mobile n-p">
        <div
          class="
            column
            is-3-desktop is-3-tablet is-6-mobile
            has-text-centered
            is-flex
          "
          v-for="(item, key) of products"
          :key="key"
        >
          <CardProducts
            :name="item.name"
            :image="item.image"
            :product="item"
            :promo="item.promo"
          />
        </div>
      </div>
      <br />
    </div>
  </div>
</template>

<script>
import CardProducts from "@/components/Card.vue";
//import Paginas from "@/components/products/Pagination.vue";
import LoadPage from "@/mixins/load.js";
import axios from "axios";

export default {
  name: "AllProducts",
  components: {
    CardProducts,
    //  Paginas,
  },
  data() {
    return {
      products: {},
    };
  },
  methods: {
    async getProducts() {
      const characters = await axios.get(
        "https://rickandmortyapi.com/api/character/"
      );
      this.products = characters.data.results;
      this.products.forEach((element) => {
        element.id % 3 == 0 ? (element.promo = true) : (element.promo = false);
      });
    },
  },
  created() {
    this.getProducts();
  },
  mixins: [LoadPage],
};
</script>


<style scoped>
h2 {
  font-size: 30px;
  color: #333;
  margin: 25px 0px;
  font-weight: 600;
  text-align: justify;
  padding: 0px 20px;
}
h2:after {
  display: block;
  height: 3px;
  background-color: #f21313;
  content: "";
  width: 120px;
  margin-top: 10px;
  box-shadow: 1px 1px 3px #666;
  margin-bottom: 40px;
}
</style>