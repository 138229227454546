<template>
  <div class="container formularios columns is-multiline">
    <div v-if="quita" class="col column is-6-desktop is-12-mobile">
      <Login :vers="muestrate" />
    </div>
    <div v-if="quita" class="col column is-6-desktop is-12-mobile">
      <Guest @value="ocultar()" />
    </div>
    <div v-if="muestrate" class="column is-12">
      <GuestShop />
    </div>
  </div>
</template>

<script>
import Login from "@/components/Login.vue";
import Guest from "@/components/shop/PayAs.vue";
import GuestShop from "@/components/shop/GuestShop.vue";

export default {
  name: "ShopCar",
  components: {
    Login,
    Guest,
    GuestShop,
  },
  data() {
    return {
      quita: true,
      muestrate: false,
      invi: [
        {
          name: "",
          last: "",
          email: "",
        },
      ],
    };
  },
  methods: {
    ocultar(value) {
      this.quita = value;
      this.muestrate = true;
    },
  },
};
</script>


<style scoped>
.container {
  margin: 0 auto;
  margin-bottom: 20px;
  margin-top: 5px;
}
</style>