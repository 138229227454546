export default {
    methods: {
        loading() {
            const load = this.$buefy.loading.open({
                container: null,
            });
            setTimeout(() => load.close(), 1000);
        },
    },
    mounted() {
        this.loading();
    },
}