<template>
  <div class="datoFactura">
    <div class="forms field">
      <h2 v-if="ver">Usted es:</h2>
      <button id="btn1" class="boton2" @click="escoger(1)" :class="clase1">
        Persona física
      </button>
      <button id="btn2" class="boton2" @click="escoger(2)" :class="clase2">
        Persona moral
      </button>
      <div class="formulario1">
        <form action="" id="form1" class="form1" v-if="ver1">
          <div class="contenido">
            <div class="labels">
              <label for="">RFC</label>
              <label for="">Clave IEPS </label>
              <label for="">CFDI </label>
              <label for="">CORREO </label>
            </div>
            <div class="inputs">
              <input class="input is-info" type="text" />
              <input class="input is-info" type="text" />
              <input class="input is-info" type="text" />
              <input class="input is-info" type="text" />
            </div>
          </div>

          <button class="boton">Guardar</button>
        </form>
      </div>
      <div class="formulario2">
        <form action="" id="form2" class="form2" v-if="ver2">
          <div class="contenido">
            <div class="labels">
              <label for="">RFC</label>
              <label for="">Clave IEPS </label>
              <label for="">CORREO </label>
            </div>
            <div class="inputs">
              <input class="input is-info d" type="text" />
              <input class="input is-info d" type="text" />
              <input class="input is-info d" type="text" />
            </div>
          </div>
          <button class="boton">Guardar</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InvoiceShop",
  data() {
    return {
      ver: true,
      ver1: false,
      ver2: false,
    };
  },
  methods: {
    escoger(id) {
      console.log("esto es id general= " + id);
      if (id === 1) {
        console.log("esto es ver1= " + this.ver1);
        console.log("esto es id= " + id);
        this.ver = false;
        this.ver1 = true;
        this.ver2 = false;
        console.log("esto es ver1= " + this.ver1);
        document.getElementById("btn1").style.backgroundColor = "#4590bf";
        document.getElementById("btn1").style.color = "white";
        document.getElementById("btn2").style.backgroundColor = "white";
        document.getElementById("btn2").style.color = "#4590bf";
      } else if (id === 2) {
        console.log("esto es ver2= " + this.ver2);
        console.log("esto es id= " + id);

        this.ver2 = true;
        this.ver = false;
        this.ver1 = false;
        console.log("esto es ver2= " + this.ver2);

        document.getElementById("btn2").style.backgroundColor = "#4590bf";
        document.getElementById("btn2").style.color = "white";
        document.getElementById("btn1").style.backgroundColor = "white";
        document.getElementById("btn1").style.color = "#4590bf";
      }
    },
  },
};
</script>

<style scoped>
#btn1,
#btn2 {
  margin: 20px 7px;
}
.datoFactura {
  background-color: white;
  width: 480px;
  box-shadow: 0px 0px 7px #aaa;
  margin: 0 auto;
  border-radius: 5px;
  padding: 10px;
}
h2 {
  font-size: 20px;
  color: #0339a6;
  font-weight: 600;
}
.contenido {
  display: flex;
  padding: 10px;
}
.labels {
  width: 27%;
  text-align: left;
  padding-left: 20px;
}
label {
  display: block;
  margin: 24% 10px;
}
.inputs {
  width: 60%;
  padding-top: 10px;
}
input {
  height: 15%;
  margin: 8.5px 10px;
  padding: 5px;
  background: none;
}
.d {
  margin: 10px 10px;
  padding: 9px 5px;
  height: 19%;
}
.is-info {
  border: 1px solid #ccc;
}
.boton {
  background-color: #4590bf;
  color: white;
  border: 1px solid #4590bf;
  margin-right: auto;
  border-radius: 5px;
  padding: 7px 25px;
  font-size: 16px;
  margin-bottom: 20px;
}
.boton:hover {
  background-color: #4590bf;
  color: white;
  border: 1px solid #4590bf;
  font-weight: 600;
  box-shadow: 1px 1px 10px #4590bf;
}
.boton2 {
  color: #4590bf;
  border: 1px solid #4590bf;
  background-color: white;
  margin-right: auto;
  border-radius: 5px;
  padding: 10px 15px;
  font-size: 17px;
  font-weight: 600;
  margin-right: 10px;
}
.boton2:hover {
  color: #4590bf;
  border: 1px solid #4590bf;
  background-color: white;
  box-shadow: 1px 1px 10px #4590bf;
  font-weight: 600;
}
@media screen and (max-width: 650px) {
  .datoFactura {
    width: 70%;
    margin: 0 auto;
  }
  .labels {
    width: 33%;
    padding-left: 0px;
  }
  .inputs {
    width: 60%;
    padding-top: 13px;
  }
  label {
    font-size: 75%;
    margin: 25.5% 10px;
  }
  input {
    height: 15%;
    margin: 3.5% 5px;
    padding: 5px;
  }
  .boton,
  .boton2 {
    width: 42%;
    padding: 5px 5px;
    font-size: 90%;
  }
  .d {
    margin: 4.2% 10px;
    padding: 8px 5px;
    height: 16%;
  }
}
</style>