<template>
  <div class="error"></div>
</template>

<script>
export default {
  name: "ErrorPage",
  created() {
    this.alertCustomError();
  },
  methods: {
    alertCustomError() {
      this.$buefy.dialog.alert({
        title: "Error 404",
        message: "Página no encontrada, se rediccionará a la pagina principal",
        type: "is-danger",
        hasIcon: true,
        icon: "times-circle",
        iconPack: "fa",
        ariaRole: "alertdialog",
        ariaModal: true,
        confirmText: "Entendido",
        onConfirm: () => this.$router.push("/"),
      });
    },
  },
};
</script>

<style scoped>
.error {
  height: 90vh;
}
</style>