<template>
  <div class="container">
    <div class="tarjeton columns">
      <div class="tarjeton-image column is-6-tablet">
        <img
          class="image is-256x256"
          :src="product.image"
          alt="Foto Producto"
        />
      </div>
      <div class="tarjeton-info column is-6-desktop">
        <div class="doble">
          <h2 class="column is-7">{{ product.name }}</h2>
          <p class="column is-5 precio">$ {{ product.id }}</p>
        </div>
        <p>Descripción</p>
        <p class="desc">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestiae
          asperiores corporis nemo blanditiis dolor ex nulla voluptate nesciunt
          possimus. Accusantium eaque nostrum quaerat, inventore mollitia
          deserunt numquam non libero rem.
        </p>
        <div class="tarjeton-compra">
          <p class="control envio">
            <i class="fa-solid fa-truck-fast"></i> Tiempo de entrega aproximado:
            10 días
          </p>
          <p class="control">
            <button class="boton2" @click="addProduct(product)">
              Agregar al carrito</button
            ><button class="boton">Comprar ahora</button>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "DetailsProduct",
  props: {
    product: {},
  },
  methods: {
    addProduct(item) {
      this.addToCar(item).then(
        (response) => {
          this.$buefy.toast.open({
            message: response,
            type: "is-third",
          });
        },
        (error) => {
          this.$buefy.toast.open({
            message: "Oops! Error al agregar! 😐",
            type: "is-second",
          });
          console.error(error.message);
        }
      );
      this.getPieces();
      this.getTotal();
    },
    ...mapActions(["addToCar", "getPieces", "getTotal"]),
  },
};
</script>

<style scoped>
.container {
  margin-bottom: 30px;
}
.tarjeton {
  /* height: 450px; */
  box-shadow: 1px 1px 10px #efefef;
  border-radius: 10px;
  background-color: #f8f8f8;
}

h2 {
  font-size: 25px;
  color: #333;
  font-weight: 600;
  text-align: justify;
  padding: 0px 20px;
}
h2:after {
  display: block;
  height: 3px;
  background-color: #f21313;
  content: "";
  width: 100px;
  margin-top: 10px;
  box-shadow: 1px 1px 3px #666;
  margin-bottom: 10px;
}
.boton {
  background-color: #4590bf;
  color: white;
  border: 1px solid #4590bf;
  margin-right: auto;
  border-radius: 5px;
  padding: 10px 15px;
  font-size: 17px;
  font-weight: 600;
}
.boton:hover {
  background-color: #4590bf;
  color: white;
  border: 1px solid #4590bf;
  font-weight: 600;
  box-shadow: 1px 1px 10px #4590bf;
}
.boton2 {
  color: #4590bf;
  border: 1px solid #4590bf;
  background-color: white;
  margin-right: auto;
  border-radius: 5px;
  padding: 10px 15px;
  font-size: 17px;
  font-weight: 600;
  margin-right: 10px;
}
.boton2:hover {
  color: #4590bf;
  border: 1px solid #4590bf;
  background-color: white;
  box-shadow: 1px 1px 10px #4590bf;
  font-weight: 600;
}
.doble {
  display: flex;
}
p {
  font-size: 16px;
  text-align: justify;
  font-weight: 600;
  padding: 5px 20px;
}
.envio {
  color: #4590bf;
  padding-bottom: 20px;
}
.desc {
  font-weight: 400;
}
.precio {
  color: #0339a6;
  font-weight: 600;
  font-size: 20px;
  margin-top: 4%;
  text-align: right;
}
.control {
  text-align: right;
  margin: 10px;
}
.tarjeton-compra {
  margin-top: 15%;
}
.image {
  border-radius: 5px;
  box-shadow: 1px 1px 7px #ccc;
}
@media screen and (min-width: 769px) and (max-width: 850px) {
  .boton2,
  .boton {
    font-size: 14px;
    /* margin-right: 0px;
    margin-bottom: 10px; */
  }
  /* .boton{
    width: 165px;
  } */
  h2 {
    padding: 0px 5px;
  }
  p {
    padding: 5px;
  }

  .tarjeton-image {
    margin-left: 15px;
  }
  .tarjeton-info {
    margin-right: 10px;
  }
}
@media screen and (min-width: 304px) and (max-width: 769px) {
  .tarjeton-image img {
    width: 90%;
    margin: 0 auto;
  }
}
@media screen and (min-width: 350px) and (max-width: 404px) {
  .boton2,
  .boton {
    font-size: 14px;
    /* margin-right: 0px;
    margin-bottom: 10px; */
  }
  .envio {
    font-size: 13px;
  }
}
</style>