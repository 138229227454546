<template>
  <div class="container">
    <b-carousel-list
      :data="items"
      :items-to-show="numberCards"
      :repeat="true"
      class="has-shadow mb-6 is-12-mobile"
      ref="carousel"
    >
      <template #item="list">
        <div class="card p-4 mx-2">
          <div class="card-image">
            <figure class="image is-5by4">
              <a @click="info(list.index)"
                ><img
                  :src="list.image"
                  @click="$router.push(`/products/${list.id}`)"
              /></a>
            </figure>
            <b-tag type="is-second" rounded class="tag-promo" v-if="list.promo"
              ><b> Promo </b></b-tag
            >
          </div>
          <div class="card-content">
            <p class="title is-size-6 is-size-7-mobile has-text-centered">
              {{ list.name }}
            </p>
          </div>
          <div class="card-footer">
            <p
              class="
                is-size-7-mobile is-size-6-tablet is-size-6-desktop
                has-text-justified
                p-2
                has-text-grey
              "
            >
              Lorem, ipsum dolor sit amet consectetur adipisicing elit.
            </p>
            <p class="has-text-right has-text-second has-text-weight-bold">
              ${{ list.id }}&nbsp;MXN
            </p>
          </div>
        </div>
      </template>
    </b-carousel-list>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "FeaturedProducts",
  data() {
    return {
      items: [],
      numberCards: 4,
      window: {
        width: 0,
        height: 0,
      },
      intervalRepeat: null,
    };
  },
  methods: {
    info(value) {
      this.test = value;
    },
    resize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;

      if (this.window.width > 850) this.numberCards = 4;
      if (this.window.width <= 850 && this.window.width > 650)
        this.numberCards = 3;
      if (this.window.width <= 650 && this.window.width > 450)
        this.numberCards = 2;
      if (this.window.width <= 450) this.numberCards = 1.5;
    },
    async getItems() {
      const characters = await axios.get(
        "https://rickandmortyapi.com/api/character"
      );
      this.items = characters.data.results;
      this.items.forEach((element) => {
        element.id % 3 == 0 ? (element.promo = true) : (element.promo = false);
      });
      console.log(this.products);
    },
  },
  created() {
    window.addEventListener("resize", this.resize);
    this.resize();
    this.intervalRepeat = setInterval(() => {
      try {
        this.$refs.carousel.next();
      } catch (e) {
        e instanceof TypeError ? console.log("next") : console.log(e.message);
      }
    }, 3000);
    this.getItems();
  },
  destroyed() {
    window.removeEventListener("resize", this.resize);
  },
};
</script>

<style>
.has-shadow {
  box-shadow: none !important;
}
.card {
  height: 100%;
}
.tag-promo {
  position: absolute;
  top: 0;
  left: -10px;
  transform: rotate(-20deg);
}
.card-footer {
  display: block;
}
</style>