<template>
  <div class="pie mt-6">
    <div class="container">
      <div class="columns is-mobile">
        <div class="column is-4">
          <p>
            Encuentranos en:
            <br />
            Calle 71 No. 412-c x 32 y 34 Col. Centro <br />
            Teléfono (999) 9298072
          </p>
        </div>
        <div class="second column is-4">
          <img class="image is-48x48" src="@/assets/img/compumacc.png" alt="" />
          <p>
            Todos los derechos reservados <br />
            &copy; copyright 2022
          </p>
        </div>
        <div class="contact column is-4">
          <p>
            <a href="https://www.facebook.com/CompuMACC" target="blank"
              >&nbsp;<i class="fab fa-facebook"></i> Compumaac</a
            >
          </p>
          <p>&nbsp;<i class="fab fa-whatsapp"></i> (999) 9298072</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterPage",
};
</script>

<style scoped>
.column {
  margin: 15px;
  text-align: center;
}

.pie {
  background-color: #4590bf;
}
a {
  text-decoration: none;
  color: white;
}
p {
  color: white;
  font-size: 12px;
}

.second {
  margin: 0 auto;
}
.second img {
  margin: 0 auto;
}
.contact {
  padding-top: 20px;
  text-align: center;
  padding-left: 20px;
}
@media screen and (max-width: 750px) {
  .column {
    margin: 5px;
    padding: 10px 5px;
  }
  p {
    font-size: 8px;
  }
  .second {
    padding: 5px;
  }
  .second .image {
    width: 18%;
    height: 50%;
  }
}
</style>