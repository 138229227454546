<template>
  <c div class="container contact-us">
    <div class="mapp">
      <TitlesBand title="Contáctanos" />
      <p
        class="
          mx-auto
          mt-3
          is-size-5-desktop is-size-6-tablet is-size-7-mobile
          column
          is-10
          has-text-weight-light
        "
      >
        Si no encuentras lo que b&uacute;scas o tienes dudas sobre algún
        producto puedes encontrarnos en:
      </p>
      <div class="card maps container mx-auto">
        <div class="card-content columns is-12 mx-auto">
          <div class="goo-maps column mx-auto">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3725.901303784372!2d-89.60989188570342!3d20.956478295619263!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8f5671735f2791cd%3A0x9ea7bd24c5576597!2sCompuMACC!5e0!3m2!1ses-419!2smx!4v1659130921730!5m2!1ses-419!2smx"
              width="90%"
              height="225"
              style="border: 0"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
          <div
            class="
              description
              column
              is-flex is-justify-content-center is-align-items-center
              has-text-left
              is-size-5-desktop is-size-6-touch is-size-7-mobile
            "
          >
            <div>
              <p class="mt-2">
                <i class="fa-solid fa-mobile-screen-button"></i>
                Celular: (999) 73-88-618
              </p>
              <p class="mt-2">
                <a
                  href="https://www.google.com.mx/maps/place/CompuMACC/@20.9564783,-89.6098919,17z/data=!4m5!3m4!1s0x8f5671735f2791cd:0x9ea7bd24c5576597!8m2!3d20.9564733!4d-89.6077032"
                  target="_blank"
                >
                  <i class="fa-solid fa-location-dot"></i>
                  Calle 71 No. 412-c x 32 y 34 Col. Centro
                </a>
              </p>
              <p class="mt-2">
                <i class="fa-solid fa-phone"></i>
                Tel&eacute;fono: (999) 92-98-072
              </p>
              <p class="mt-2">
                <a :href="message" target="_blank">
                  <i class="fa-brands fa-whatsapp"></i>
                  Enviar un mensaje
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <p
      class="
        container
        mx-auto
        mt-5
        mb-5
        is-size-5-desktop is-size-6-tablet is-size-7-mobile
        column
        is-10
        has-text-weight-light
        ml-2
      "
    >
      O bien, m&aacute;ndanos un mensaje con lo siguiente:
    </p>

    <div class="form-contact mb-6 has-box-shadow container mx-auto ml-2">
      <form @submit.prevent="sendMail" class="card columns is-8">
        <section
          class="column is-10-mobile is-8-tablet is-6 mx-auto card-content"
        >
          <b-field horizontal label="Nombre">
            <b-input name="name" placeholder="Nombre, Apellido"></b-input>
          </b-field>

          <b-field horizontal label="Contacto">
            <b-input name="phone" placeholder="Número de celular"></b-input>
            <b-input
              name="email"
              type="email"
              placeholder="correo@example.com"
              expanded
            ></b-input>
          </b-field>

          <b-field horizontal label="Mensaje">
            <b-input type="textarea" name="message"></b-input>
          </b-field>

          <b-field horizontal
            ><!-- Label left empty for spacing -->
            <p class="control">
              <b-button label="Send message" type="is-primary" />
            </p>
          </b-field>
        </section>
      </form>
    </div>
  </c>
</template>

<script>
import TitlesBand from "@/components/TitlesBand.vue";
import LoadPage from "@/mixins/load.js";

export default {
  name: "ContactUs",
  components: { TitlesBand },
  data() {
    return {
      message:
        "https://wa.me/+5219997388618?text=Hola, quiero consultar un producto.",
    };
  },
  methods: {
    sendMail() {
      console.log("Enviando correo...");
    },
  },
  mixins: [LoadPage],
};
</script>


<style scoped>
.maps {
  margin: 20px;
}
.description {
  border-left: 1px solid #4590bf;
  height: 200px;
  margin-top: 20px;
}
.title-contact {
  border-bottom: 1px solid red;
  width: 25%;
}
</style>