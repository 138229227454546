<template>
  <div class="is-fluid">
    <div class="top-nav is-block has-background-primary is-fluid">
      <div
        class="
          level
          container
          has-text-white
          is-size-6 is-size-7-mobile
          p-1
          has-text-centered
          is-mobile
        "
      >
        <div class="level-left">
          <p>S&iacute;guenos en:&nbsp;</p>
          <a
            href="https://www.facebook.com/people/CompuMACC/100067803527708/"
            target="_blank"
            class="has-text-white ml-2"
          >
            <i class="fa-brands fa-facebook"></i>&nbsp;
          </a>
          <a
            href="https://m.me/100067803527708"
            target="_blank"
            class="has-text-white ml-2"
          >
            <i class="fa-brands fa-facebook-messenger"></i>&nbsp;
          </a>
          <a
            href="https://wa.me/9997388618?text=Hola, quiero consultar un producto"
            target="_blank"
            class="has-text-white ml-2"
          >
            <i class="fa-brands fa-whatsapp"></i>
          </a>
        </div>
        <div class="level-right">
          <p v-on:click="showModalLogin = true" class="is-clickable">
            Inicia sesi&oacute;n
          </p>
          &nbsp; | &nbsp;
          <p v-on:click="showModalRegister = true" class="is-clickable">
            Reg&iacute;strate
          </p>
        </div>
      </div>
    </div>

    <nav
      class="navbar container bottom-nav"
      role="navigation"
      aria-label="main navigation"
    >
      <div class="navbar-brand">
        <RouterLink to="/" class="image is-80x80 mt-2">
          <img src="@/assets/img/compumacc.png" />
        </RouterLink>

        <a
          role="button"
          class="navbar-burger"
          aria-label="menu"
          aria-expanded="false"
          data-target="navbarBasicExample"
          v-on:click="setActive"
          v-bind:class="{ 'is-active': isActive }"
        >
          <transition name="fade">
            <i v-if="down">
              <i class="fa-solid fa-bars menu-icon down"></i>
            </i>
          </transition>
          <transition name="fade">
            <i v-if="isActive">
              <i class="fa-solid fa-xmark menu-icon"></i>
            </i>
          </transition>
        </a>
      </div>

      <div
        id="navbarBasicExample"
        class="navbar-menu"
        v-bind:class="{ 'is-active': isActive }"
      >
        <div class="has-text-centered is-block nav-center">
          <div class="search">
            <b-field>
              <b-input placeholder="Buscar en compumacc..." expanded></b-input>
              <p class="control">
                <b-button label="Search" type="is-primary" />
              </p>
            </b-field>
          </div>
          <span
            class="has-text-centered"
            v-for="(item, key) of navigation"
            :key="key"
          >
            <RouterLink :to="item.path">
              <a
                href=""
                class="navbar-item has-text-second d-inline item"
                @click="setDisabled"
              >
                {{ item.title }}
              </a>
            </RouterLink>
          </span>
        </div>

        <div class="navbar-end end">
          <b-tag type="is-second" rounded class="tag">
            <b>{{ $store.state.pieces }}</b>
          </b-tag>
          <RouterLink to="/shop/">
            <img
              src="@/assets/img/iconShop.svg"
              alt="icon shop"
              class="shop-icon"
              @click="setDisabled"
            />
          </RouterLink>
        </div>
      </div>
    </nav>

    <!---------------------------------- LOGIN--------------------------------- -->
    <transition name="fade">
      <div class="modal-overlay1" v-if="showModalLogin">
        <div class="color"></div>
      </div>
    </transition>
    <transition name="fade">
      <div class="modal-overlay" v-if="showModalLogin">
        <Login @value="getCloseLogin()" />
      </div>
    </transition>
    <!---------------------------------- REGISTRO--------------------------------- -->
    <transition name="fade">
      <div class="modal-overlay1" v-if="showModalRegister">
        <div class="color"></div>
      </div>
    </transition>
    <transition name="fade">
      <div class="modal-overlay" v-if="showModalRegister">
        <Register @value="getCloseRegister()" />
      </div>
    </transition>
  </div>
</template>

<script>
import Login from "@/components/Login.vue";
import Register from "@/components/Register.vue";
import { mapActions } from "vuex";

export default {
  components: {
    Login,
    Register,
  },
  data() {
    return {
      count: 0,
      isActive: false,
      down: true,
      navigation: [
        {
          title: "Inicio",
          path: "/",
        },
        {
          title: "Productos",
          path: "/products/",
        },
        {
          title: "Servicios",
          path: "/services",
        },
        {
          title: "Contáctanos",
          path: "/contact",
        },
      ],
      showModalLogin: null,
      showModalRegister: null,
    };
  },
  methods: {
    setActive() {
      if (this.isActive) {
        this.down = true;
        this.isActive = false;
      } else {
        this.down = false;
        this.isActive = true;
      }
    },
    setDisabled() {
      this.down = true;
      this.isActive = false;
    },
    getCloseLogin(value) {
      this.showModalLogin = value;
    },
    getCloseRegister(value) {
      this.showModalRegister = value;
    },
    ...mapActions(["verifyShopCar"]),
  },
  created() {
    this.verifyShopCar();
  },
};
</script>

<style scoped>
/* Nav */
.top-nav {
  min-height: 30px;
}
.bottom-nav {
  min-height: 90px;
  margin-top: 10px;
}
.nabvar {
  min-height: 150px !important;
}
/* margin to mobile view */
.level-right {
  margin-top: 0px;
}
/* size for logo */
.is-80x80 {
  width: 85px !important;
  height: 85px !important;
  margin-top: 0px !important;
}
/* search and items nav */
.nav-center {
  width: 90%;
  margin: 0px 20px 0px 20px;
}
.d-inline {
  display: inline-block !important;
}
/* image shop */
.shop-icon {
  margin-top: -2px !important;
  width: 70px;
  height: 70px;
  color: #f21313;
}
/* icon menu mobile */
.menu-icon {
  font-size: 30px;
  padding-top: 10px;
  color: #f21313;
}
/* item to options nav */
.item {
  width: 110px;
}
/* hover item nav */
.item:hover::after {
  display: block;
  height: 1px;
  background-color: #f21313;
  content: "";
  width: 100px;
  margin-top: 10px;
  box-shadow: 1px 1px 2px #666;
  margin-bottom: 5px;
  margin: 0 auto;
}
.navbar-burger {
  min-height: 60px;
  text-align: center;
  margin-top: 10px;
}
.tag {
  position: relative;
  top: 50px;
  left: 10px;
}
/* transition mobile menu */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  transition: 0s;
  opacity: 0;
}
.down {
  font-size: 30px;
  color: #4590bf;
}
.blanco {
  border: 0;
  background: none;
  color: white;
  padding: 0px 5px;
}

/* Modal Styles */
.modal-overlay1 {
  position: fixed;
  margin: 0 auto;
  top: 0%;
  right: 0%;
  left: 0%;
  bottom: 0%;
  z-index: 100;
  background: white;
}
.color {
  background-color: #4590bf;
  height: 50%;
}
.modal-overlay {
  position: fixed;
  margin: 0 auto;
  top: 50%;
  left: 50%;
  z-index: 101;
  transform: translate(-50%, -50%);
}
.cerrar {
  text-align: right;
}
.cerrar button {
  background: none;
  color: white;
  font-size: 80%;
  margin-right: 29%;
  border: 1px solid #888;
  padding: 2px 6px;
  border-radius: 0px;
}
@media screen and (max-width: 1023px) {
  .shop-icon {
    width: 60px;
    height: 60px;
  }
  .tag {
    top: 5px;
  }
  .search {
    margin: auto;
    width: 80%;
  }
  .end {
    display: block;
    width: 40% !important;
    text-align: center !important;
    margin: auto;
    margin-bottom: 10px;
    margin-top: 5px;
    padding-right: 5%;
  }
  .d-inline {
    display: block;
    margin: auto;
    width: 60%;
  }
}
</style>