import Vue from 'vue'
import Vuex from "vuex"

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    shopCar: {},
    pieces: 0,
    total: 0
  },
  mutations: {
    addShopCar(state, payload) {
      state.shopCar[payload.id] = payload;
      localStorage.shopCar = JSON.stringify(state.shopCar);
    },
    setShopCar(state, payload) {
      state.shopCar = payload;
    },
    setEmpyCar(state) {
      state.shopCar = {}
    },
    setPieces(state, payload) {
      state.pieces = payload;
    },
    setTotal(state, payload) {
      state.total = payload;
    },
    deleteItem(state, payload) {
      state.shopCar = payload;
    }
  },
  actions: {
    addToCar({ commit, state }, product) {
      return new Promise((resolve, reject) => {
        try {
          Object.prototype.hasOwnProperty.call(state.shopCar, product.id)
            ? product.pieces = state.shopCar[product.id].pieces + 1
            : product.pieces = 1;
          commit('addShopCar', product);
          resolve('Agregado con éxito! 😀');
        } catch (e) {
          reject(e);
        }

      });
    },
    verifyShopCar({ commit }) {
      if (localStorage.shopCar) {
        commit('setShopCar', JSON.parse(localStorage.shopCar));
      } else {
        commit('setShopCar', {});
      }
    },
    deleteProduct({ state }, product) {
      if (Object.hasOwnProperty.call(state.shopCar, product.id)) {
        delete state.shopCar[product.id];
      }
    },
    getTotal({ commit, state }) {
      commit("setTotal", Object.values(state.shopCar).reduce((acc, item) => acc + (item.id * item.pieces), 0));
    },
    getPieces({ commit, state }) {
      commit("setPieces", Object.values(state.shopCar).reduce((acc, { pieces }) => acc + pieces, 0));
    }
  },
  modules: {
  }
})
