<template>
  <div>
    <div class="container mt-3 mb-3">
      <p
        class="
          title
          has-text-left
          is-size-4-mobile is-size-3-tablet is-size-3-desktop
        "
      >
        Comentarios
      </p>
      <div class="comentarios columns is-talet">
        <div class="column is-4">
          <div class="cuadro">
            <p class="de"><span>De: @user</span></p>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi
              culpa, voluptatem deserunt veniam vero adipisci totam velit,
              cupiditate earum, facere nostrum!
            </p>
          </div>
        </div>
        <div class="column is-4">
          <div class="cuadro">
            <p class="de"><span>De: @user</span></p>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi
              culpa, voluptatem deserunt veniam vero adipisci totam velit,
              cupiditate earum, facere nostrum!
            </p>
          </div>
        </div>
        <div class="column is-4">
          <div class="cuadro">
            <p class="de"><span>De: @user</span></p>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi
              culpa, voluptatem deserunt veniam vero adipisci totam velit,
              cupiditate earum, facere nostrum!
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CommentsHome",
};
</script>

<style scoped>
.title {
  color: #333;
  margin: 25px 0px;
  font-weight: 600;
  text-align: justify;
  padding: 0px 20px;
}
.title:after {
  display: block;
  height: 3px;
  background-color: #f21313;
  content: "";
  width: 120px;
  margin-top: 10px;
  box-shadow: 1px 1px 3px #666;
  margin-bottom: 40px;
}
h2 {
  font-size: 30px;
  color: #333;
  margin: 25px 0px;
  font-weight: 600;
  text-align: justify;
  padding: 0px 20px;
}
.container {
  margin-bottom: 30px;
}
.cuadro {
  box-shadow: 1px 1px 7px #666;
  padding: 12px;
  border-radius: 10px;
  text-align: justify;
  margin: 0px 20px;
}
span {
  font-weight: 600;
  margin-bottom: 5px;
  font-size: 16px;
}
p {
  color: #333;
  font-size: 14px;
}
@media screen and (max-width: 750px) {
  h2 {
    font-size: 24px;
  }
  .cuadro {
    margin: 0px 50px;
  }
  span {
    font-size: 14px;
  }
  p {
    font-size: 12px;
  }
}
</style>