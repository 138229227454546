<template>
  <div :class="`card ${padding} ${margin} is-fluid ${full}`">
    <div class="card-image p-5" v-if="product">
      <RouterLink :to="`/products/${product.id}`">
        <figure class="image is-1by1">
          <img :src="image" alt="Foto Referente 1" />
        </figure>
      </RouterLink>
      <b-tag type="is-second" rounded class="tag-promo" v-if="promo"
        ><b> Promo </b></b-tag
      >
    </div>
    <div class="card-image p-5" v-else>
      <figure class="image is-1by1">
        <img :src="image" alt="Foto Referente 1" />
      </figure>
      <b-tag type="is-second" rounded class="tag-promo" v-if="promo"
        ><b> Promo </b></b-tag
      >
    </div>
    <div class="card-content">
      <p class="title is-5 is-size-6-mobile has-text-centered">{{ name }}</p>
    </div>
    <div class="card-footer p-2" v-if="product">
      <div class="container">
        <p
          class="
            is-size-7-mobile is-size-6-tablet is-size-6-desktop
            has-text-justified
            p-2
            has-text-grey
          "
        >
          Lorem, ipsum dolor sit amet consectetur adipisicing elit.
        </p>
        <p class="has-text-right has-text-second has-text-weight-bold">
          ${{ product.id }}&nbsp;MXN
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardItem",
  props: {
    name: String,
    image: String,
    padding: String,
    margin: String,
    product: Object,
    full: String,
    promo: Boolean,
  },
};
</script>
<style scoped>
.full {
  width: 100% !important;
}
.card:hover {
  background-color: #f7f7f7;
}
.card-content {
  margin: -20px -10px;
}
.n-p {
  margin-top: -30px;
}
.tag-promo {
  position: absolute;
  top: 0;
  left: -10px;
  transform: rotate(-20deg);
}
@media screen and (max-width: 769px) and (min-width: 600px) {
  .card-image {
    margin: auto;
    width: 85%;
    height: auto;
  }
  .card {
    width: 85%;
    margin: auto;
  }
  .mg-mob {
    margin-top: 10px;
  }
}

@media screen and (max-width: 599px) and (min-width: 400px) {
  .card-image {
    margin: auto;
    width: 80%;
    height: auto;
  }
  .card {
    width: 90%;
    margin: auto;
  }
  .mg-mob {
    margin-top: 10px;
  }
}

@media screen and (max-width: 399px) {
  .card-image {
    margin: auto;
    width: 100%;
    height: auto;
  }
  .card {
    width: 100%;
    margin: auto;
  }
  .mg-mob {
    margin-top: 10px;
  }
}
</style>
