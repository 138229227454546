<template>
  <div class="continuar columns">
    <div class="column is-8">
      <div class="entrega">
        <h2>Datos de Entrega</h2>
        <div class="datos">
          <p class="per is-size-6">Persona que recibe:</p>
          <form name="invitado" id="invitado" action="">
            <div class="b">
              <b-field horizontal label="Nombre:">
                <b-input
                  type="text"
                  placeholder="Nombre(s)"
                  icon="account"
                  required
                  name="nombre"
                >
                </b-input>
              </b-field>
              <b-field horizontal label="Apellido:">
                <b-input
                  type="text"
                  placeholder="Apellido(s)"
                  icon="account"
                  required
                  name="apellido"
                >
                </b-input>
              </b-field>
              <b-field horizontal label="Dirección:">
                <b-input
                  placeholder="Dirección"
                  type="text"
                  icon="home"
                  required
                  name="direccion"
                >
                </b-input>
                <b-input
                  type="number"
                  placeholder="Codigo Postal"
                  icon="map"
                  minlength="5"
                  maxlength="5"
                  required
                  name="cp"
                >
                </b-input>
              </b-field>
              <b-field horizontal label="Contacto">
                <b-input
                  placeholder="Teléfono"
                  type="tel"
                  minlength="10"
                  maxlength="10"
                  number
                  icon="phone"
                  pattern="[0-9]*"
                  required
                  name="telefono"
                >
                </b-input>

                <b-input
                  horizontal
                  label="Correo"
                  placehol
                  der="Correo"
                  type="email"
                  icon="email"
                  color="primary"
                  required
                  name="email"
                >
                </b-input>
              </b-field>
            </div>
            <p>
              <RouterLink to="/shop">
                <b-button class="boton2">Cancelar</b-button>
              </RouterLink>
              <b-button class="boton" type="submit" @click="procesar()"
                >Continuar</b-button
              >
            </p>
          </form>
        </div>
      </div>
    </div>
    <div class="column is-4">
      <div class="detalle">
        <h2>Detalles</h2>
        <p>
          Total <span class="azul">${{ subtotal }}</span>
        </p>
        <div class="has-text-right">
          <b-button class="boton">Pagar</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GuestView",
};
</script>
<style scoped>
.entrega {
  box-shadow: 0px 0px 5px #666;
  padding: 10px;
  border-radius: 10px;
}
h2 {
  width: 100%;
  margin: 0 auto;
  padding: 5px 10px;
  font-size: 25px;
  color: #333;
  font-weight: 600;
  text-align: center;
}
h2:after {
  display: block;
  height: 3px;
  background-color: #4590bf;
  content: "";
  width: 100px;
  margin-top: 10px;
  box-shadow: 1px 1px 3px #666;
  margin-bottom: 10px;
  margin: 0 auto;
}
form {
  text-align: right;
}
.b {
  text-align: left;
  width: 95%;
  margin: 0 auto;
}

.boton {
  background-color: #4590bf;
  color: white;
  border: 1px solid #4590bf;
  margin-right: 3%;
  border-radius: 5px;
  padding: 8px 25px;
  font-size: 16px;
  margin-bottom: 20px;
  width: 20%;
}
.boton:hover {
  background-color: #4590bf;
  color: white;
  border: 1px solid #4590bf;
  font-weight: 600;
  box-shadow: 1px 1px 10px #4590bf;
}
.boton2 {
  color: #4590bf;
  border: 1px solid #4590bf;
  background-color: white;
  margin-right: auto;
  border-radius: 5px;
  padding: 5px 25px;
  font-size: 16px;
  margin-bottom: 20px;
  margin-right: 10px;
  width: 20%;
}
.boton2:hover {
  color: #4590bf;
  border: 1px solid #4590bf;
  background-color: white;
  box-shadow: 1px 1px 10px #4590bf;
  font-weight: 600;
}
.detalle {
  box-shadow: 0px 0px 5px #666;
  padding: 10px;
  border-radius: 10px;
}
p {
  font-size: 20px;
  padding-bottom: 5%;
  padding-top: 5%;
  color: black;
}
.azul {
  padding-left: 20%;
  color: #4590bf;
  font-weight: 600;
}
.per {
  text-align: left;
  padding: 10px;
  padding-left: 20px;
  color: black;
  font-size: 16px;
}
</style>


<script>
export default {
  data() {
    return {
      subtotal: "980,898.00",
    };
  },
  methods: {
    procesar() {
      if (document.invitado.nombre.value.length == 0) {
        document.invitado.nombre.focus();
        return 0;
      }
      if (document.invitado.apellido.value.length == 0) {
        document.invitado.apellido.focus();
        return 0;
      }
      if (document.invitado.direccion.value.length == 0) {
        document.invitado.direccion.focus();
        return 0;
      }
      if (document.invitado.cp.value.length == 0) {
        document.invitado.cp.focus();
        return 0;
      }
      if (document.invitado.telefono.value.length == 0) {
        document.invitado.telefono.focus();
        return 0;
      }
      if (document.invitado.email.value.length == 0) {
        document.invitado.email.focus();
        return 0;
      }
      document.invitado.submit();
      alert("se Envio");
    },
    borrar() {
      document.invitado.reset();
      alert("se Eo");
    },
  },
};
</script>