<template>
  <div
    class="container has-text-centered has-text-bold is-second is-size-2 mt-5"
  >
    <h2>{{ title }}</h2>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
  },
};
</script>
<style scoped>
h2:after {
  display: block;
  height: 3px;
  background-color: #4590bf;
  content: "";
  width: 150px;
  margin: 0 auto;
  margin-top: 10px;
  box-shadow: 1px 1px 3px #666;
  margin-bottom: 40px;
}
.container {
  margin-bottom: 40px;
}
@media screen and (max-width: 750px) {
  h2 {
    font-size: 24px;
  }
}
</style>
