<template>
  <div>
    <div class="container">
      <div class="logueo">
        <div class="encabezado">
          <h2>Como invitado</h2>
        </div>
        <form
          name="login"
          class="login column is-12-mobile"
          v-on:submit.prevent=""
          method="POST"
          action=""
        >
          <div class="base">
            <section>
              <b-field horizontal label="Nombre(s)" column>
                <b-input
                  placeholder="Nombre"
                  type="text"
                  icon="account"
                  v-model="invitado.nombre"
                  required
                ></b-input>
              </b-field>
              <b-field horizontal label="Apellido(s)" column>
                <b-input
                  placeholder="Apellido"
                  type="text"
                  icon="account"
                  v-model="invitado.apellido"
                  required
                ></b-input>
              </b-field>
              <b-field horizontal label="Correo ">
                <b-input
                  placeholder="Correo"
                  type="email"
                  icon="email"
                  color="primary"
                  v-model="invitado.correo"
                  required
                >
                </b-input>
              </b-field>
            </section>
          </div>
          <p>{{ invitado.nombre }}</p>
          <p>{{ invitado.apellido }}</p>
          <p>{{ invitado.correo }}</p>
          <!--  -->

          <div class="botones">
            <p>
              <b-button class="boton" type="submit" v-on:click="ocultar()"
                >Continuar</b-button
              >
            </p>
          </div>
        </form>
      </div>
    </div>
    <BackShop class="is-block mt-5" />
  </div>
</template>

<script>
import BackShop from "@/components/shop/ReturnOption.vue";

export default {
  name: "PayAs",
  components: {
    BackShop,
  },
  data() {
    return {
      muestra: null,
      invitado: [
        {
          nombre: "",
          apellido: "",
          correo: "",
        },
      ],
    };
  },
  methods: {
    ocultar() {
      this.muestra = false;
      this.$emit("value", this.muestra);
    },
  },
};
</script>

<style scoped>
.logueo {
  border-radius: 10px;
  background-color: white;
  box-shadow: 0px 0px 7px #666;
  max-width: 450px;
  min-height: 450px;
  margin: 0 auto;
}
.login {
  margin-top: 10px;
}
.encabezado {
  display: flex;
}
h2 {
  width: 100%;
  margin: 0 auto;
  padding: 5px 10px;
  font-size: 25px;
  color: #333;
  font-weight: 600;
  text-align: center;
  padding-top: 10%;
}
h2:after {
  display: block;
  height: 3px;
  background-color: #4590bf;
  content: "";
  width: 100px;
  margin-top: 10px;
  box-shadow: 1px 1px 3px #666;
  margin-bottom: 10px;
  margin: 0 auto;
}
.base {
  text-align: justify;
  margin: 0 auto;
  width: 90%;
}
.botones {
  text-align: right;
  margin-right: 5%;
  margin-top: 10%;
}
a {
  color: #4590bf;
  margin-bottom: 5%;
  margin-top: 5%;
  display: inline-block;
}
.boton a {
  text-decoration: none;
  color: white;
}
.boton {
  background-color: #4590bf;
  color: white;
  border: 1px solid #4590bf;
  margin-right: auto;
  border-radius: 5px;
  padding: 8px 25px;
  font-size: 16px;
  margin-bottom: 20px;
  width: 30%;
}
.boton:hover {
  background-color: #4590bf;
  color: white;
  border: 1px solid #4590bf;
  font-weight: 600;
  box-shadow: 1px 1px 10px #4590bf;
}
.boton2 {
  color: #4590bf;
  border: 1px solid #4590bf;
  background-color: white;
  margin-right: auto;
  border-radius: 5px;
  padding: 5px 25px;
  font-size: 16px;
  margin-bottom: 20px;
  margin-right: 10px;
  width: 30%;
}
.boton2:hover {
  color: #4590bf;
  border: 1px solid #4590bf;
  background-color: white;
  box-shadow: 1px 1px 10px #4590bf;
  font-weight: 600;
}
@media screen and (max-width: 680px) {
  .boton,
  .boton2 {
    width: 45%;
    font-size: 75%;
    padding: 6px 15px;
  }
  h2 {
    font-size: 120%;
  }
  h2::after {
    width: 50%;
    margin: 0 auto;
  }
  .logueo {
    width: 100%;
    margin: 0 auto;
  }
}
</style>