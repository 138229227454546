<template>
  <div class="detalles">
    <Detalles :product="product" />
  </div>
</template>

<script>
import Detalles from "@/components/products/Details.vue";
import LoadPage from "@/mixins/load.js";
import axios from "axios";

export default {
  components: {
    Detalles,
  },
  data() {
    return {
      product: {},
    };
  },
  created() {
    this.getProduct();
  },
  methods: {
    async getProduct() {
      const character = await axios
        .get(
          `https://rickandmortyapi.com/api/character/${this.$route.params.id}`
        )
        .catch((error) => {
          if (error.response) {
            if (error.response.status == 400)
              return this.$error({ statusCode: 404, message: "Not found" });
          }
        });
      this.product = character.data;
      console.log(character);
      /* return $this.$error({statusCode: 404, message: "not founf"}); */
    },
  },
  mixins: [LoadPage],
};
</script>
<style scoped>
.detalles {
  margin-top: 15px;
}
</style>
