<template>
  <div class="container carrito">
    <h2>Carrito de Compras</h2>
    <div class="columns is-multiline">
      <div class="car-prod column is-8 table-container">
        <table class="table is-striped-one has-text-centered">
          <thead>
            <th>Imagen</th>
            <th>nombre</th>
            <th>Piezas</th>
            <th>Precio</th>
            <th>Subtotal</th>
            <th>Acciones</th>
          </thead>
          <tr v-for="product of products" :key="product.id">
            <td><img class="image is-64x64" :src="product.image" alt="" /></td>
            <td class="text">{{ product.name }}</td>
            <td>{{ product.pieces }}</td>
            <td>{{ product.id }}</td>
            <td class="subT">${{ product.pieces * product.id }}</td>
            <td v-on:click="deleteItem(product)">
              <i class="fa-solid fa-trash has-text-second is-clickable"></i>
            </td>
          </tr>
        </table>
        <p
          class="has-text-centered has-text-weight bold"
          v-if="Object.keys(products).length === 0"
        >
          No hay productos en el carrito
        </p>
      </div>
      <div class="pagos column">
        <div class="fijar">
          <div class="total">
            <h3>Total: ${{ $store.state.total }}</h3>
            <form action="" class="">
              <label for="">
                <input
                  type="checkbox"
                  v-model="showModal"
                  v-bind:value="true"
                />
                Requiere de factura
              </label>
              <RouterLink to="/shop/pay">
                <button class="is-clickeabe">Paypal</button>
              </RouterLink>
            </form>
          </div>
          <p class="envio">
            <i class="fa-solid fa-truck-fast"></i> Tiempo de entrega aproximado:
            {{ entrega }} días
          </p>
        </div>
      </div>
      <!-- ------------------------------ ejemplo para modal----------------- -->
    </div>
    <transition name="fade">
      <div class="modal-overlay1" v-if="showModal"></div>
    </transition>
    <transition name="fade">
      <div class="modal-overlay" v-if="showModal">
        <div class="cerrar">
          <button @click="showModal = false">X</button>
        </div>
        <EmergenteFactura showModal="true" />
      </div>
    </transition>
  </div>
</template>

<script>
import EmergenteFactura from "@/components/shop/Invoice.vue";
import LoadPage from "@/mixins/load.js";
import { mapActions } from "vuex";

export default {
  name: "HomeShop",
  components: { EmergenteFactura },
  data() {
    return {
      total: 0,
      showModal: false,
      entrega: 2,
      products: this.$store.state.shopCar,
    };
  },
  mixins: [LoadPage],
  methods: {
    ...mapActions(["deleteProduct", "getTotal", "getPieces"]),
    deleteItem(item) {
      this.deleteProduct(item);
      this.products = this.$store.state.shopCar;
      this.getPieces();
      this.getTotal();
    },
  },
};
</script>

<style scoped>
.modal-overlay1 {
  position: fixed;
  margin: 0 auto;
  top: 0%;
  right: 0%;
  left: 0%;
  bottom: 0%;
  z-index: 100;
  background: rgba(0, 0, 0, 0.4);
}
.modal-overlay {
  position: absolute;
  margin: 0 auto;
  top: 0.7%;
  right: 1%;
  left: 1%;
  bottom: 0%;
  z-index: 100;
}
.cerrar {
  text-align: right;
}
.cerrar button {
  background: none;
  color: white;
  font-size: 80%;
  margin-right: 29%;
  border: 1px solid #888;
  padding: 2px 6px;
  border-radius: 0px;
}
.carrito {
  min-height: 70.4vh;
}
h2 {
  font-size: 25px;
  color: #333;
  font-weight: 600;
  text-align: justify;
}
h2:after {
  display: block;
  height: 3px;
  background-color: #f21313;
  content: "";
  width: 100px;
  margin-top: 10px;
  box-shadow: 1px 1px 3px #666;
  margin-bottom: 10px;
}
.car-prod {
  margin: 30px 0px 10px 0px;
}
.pagos {
  margin: 0px 0px 10px 0px;
}
.subT {
  font-weight: 600;
}
table {
  width: 100%;
}
td,
th {
  padding: 2px 20px;
}
td img {
  margin: 0 auto;
}
.text {
  width: 280px;
}
span {
  font-weight: 400;
}
.fijar {
  text-align: left;
}
.total {
  box-shadow: 0px 0px 5px #666;
  padding: 10px 15px 25px;
  border-radius: 10px;
}
h3 {
  font-size: 28px;
  color: #0339a6;
  text-align: center;
  margin-bottom: 20px;
}
form {
  margin: 0 auto;
  text-align: center;
}
label {
  font-size: 80%;
  color: black;
  text-align: left;
}
button {
  display: inline-block;
  padding: 5px 30px;
  border-radius: 10px;
  border: none;
  background-color: rgb(255, 210, 11);
  color: #0339a6;
  font-weight: 600;
  margin-left: 25%;
}
.envio {
  color: #4590bf;
  padding-bottom: 10px;
  margin-top: 20px;
  text-align: right;
}
@media screen and (max-width: 769px) {
  .pagos {
    margin: 5px 20px 20px 20px;
    font-size: 90%;
  }
  .car-prod {
    position: relative;
    margin: 30px 20px 10px 20px;
  }
  td img {
    width: 80%;
    height: auto;
  }
  td {
    font-size: 80%;
  }
  h2 {
    font-size: 100%;
    margin-left: 10px;
  }
}
@media screen and (max-width: 1150px) and (min-width: 769px) {
  .pagos {
    position: relative;
    font-size: 90%;
    margin: 0 auto;
  }
  .car-prod {
    width: 100%;
  }
  .carrito {
    margin-bottom: 30px;
    padding: 30px;
  }
  h2 {
    font-size: 20px;
  }
}
@media screen and (min-width: 1150px) {
  .car-prod {
    min-height: 330px;
  }
}
@media screen and (min-width: 452px) and (max-width: 750px) {
  .car-prod {
    min-height: 170px;
  }
}
</style>