<template>
  <div class="container">
    <b-carousel autoplay :interval="3000" repeat>
      <b-carousel-item v-for="(carousel, i) in carousels" :key="i">
        <section class="hero is-medium">
          <div class="hero-body has-text-centered image is-3by1">
            <img :src="carousel.image" alt="" />
          </div>
        </section>
      </b-carousel-item>
    </b-carousel>
  </div>
</template>

<script>
export default {
  data() {
    return {
      carousels: [
        {
          image: require("@/assets/img/Banner.jpg"),
        },
        {
          image: require("@/assets/img/Banner/info2.jpg"),
        },
        {
          image: require("@/assets/img/Banner/info3.jpg"),
        },
      ],
    };
  },
};
</script>

<style scoped>
.is-medium {
  min-height: 350px !important;
}
@media screen and (max-width: 800px) {
  .is-medium {
    min-height: 300px !important;
  }
}
</style>