<template>
  <div
    class="
      portada
      is-flex is-align-items-flex-end is-flex-wrap-wrap
      has-text-white
      is-size-3-desktop is-size-4-tablet is-size-5-touch
    "
  >
    {{ message }}
  </div>
</template>

<script>
export default {
  name: "PosterGeneral",
  props: {
    message: String,
  },
};
</script>

<style scoped>
.portada {
  display: block;
  width: 100%;
  height: 80vh;
  background-image: url("@/assets/img/portada.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-size: 100% 80vh;
  padding: 25px;
}

@media screen and (min-width: 200px) and (max-width: 400px) {
  .portada {
    background-size: 100% 20vh;
    height: 20vh;
    padding: 10px;
  }
}

@media screen and (min-width: 401px) and (max-width: 600px) {
  .portada {
    background-size: 100% 40vh;
    height: 40vh;
    padding: 15px;
  }
}

@media screen and (min-width: 601px) and (max-width: 800px) {
  .portada {
    background-size: 100% 60vh;
    height: 60vh;
    padding: 20px;
  }
}
</style>
