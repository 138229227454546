<template>
  <div class="logueo">
    <div class="encabezado">
      <div v-if="ver" class="imagen">
        <img
          class="image is-128x128"
          src="../assets/img/compumacc.png"
          alt=""
        />
      </div>
      <h2>Iniciar sesión</h2>
    </div>
    <form
      name="login"
      class="login column is-12 mobile"
      v-on:submit.prevent="procesar"
      method="POST"
      action=""
    >
      <div class="base">
        <section>
          <b-field horizontal label="Correo">
            <b-input
              placeholder="Correo"
              type="email"
              icon="email"
              required
              v-model="email"
            >
            </b-input>
          </b-field>
          <b-field horizontal label="Contraseña">
            <b-input
              placeholder="Contraseña"
              type="password"
              password-reveal
              id="pass"
              icon="lock"
              maxlength="32"
              minlength="8"
              required
            >
            </b-input>
          </b-field>
        </section>
      </div>

      <!--  -->

      <div class="botones">
        <router-link to="/recuperar">
          <a v-on:click="cerrarModal()"> ¿Olvidaste tu contraseña? </a>
        </router-link>
        <p>
          <b-button class="boton2" v-on:click="cerrarModal()"
            >Cancelar</b-button
          >
          <b-button class="boton" v-on:click="procesar()">Iniciar</b-button>
        </p>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: "LoginGeneral",
  data() {
    return {
      showModal: false,
      email: "",
      ver: "value",
      /* logueo:{
        email: null,
        pass: '',
      } */
    };
  },
  methods: {
    cerrarModal() {
      this.showModal = false;
      this.$emit("value", this.showModal);
    },
    procesar() {
      this.cerrarModal();
      alert("inicio");
    },
  },
};
</script>

<style scoped>
.logueo {
  border-radius: 10px;
  background-color: white;
  box-shadow: 0px 0px 7px #666;
  max-width: 450px;
  min-height: 450px;
  margin: 0 auto;
}
.login {
  margin-top: 20px;
}
.encabezado {
  display: flex;
}
.encabezado img {
  margin: 10px 10px;
  padding: 5px;
  width: 30%;
}
h2 {
  width: 75%;
  margin: 0 auto;
  margin-right: 60px;
  padding: 5px 10px;
  font-size: 25px;
  color: #333;
  font-weight: 600;
  text-align: center;
  padding-top: 10%;
}
h2:after {
  display: block;
  height: 3px;
  background-color: #4590bf;
  content: "";
  width: 100px;
  margin-top: 10px;
  box-shadow: 1px 1px 3px #666;
  margin-bottom: 10px;
  margin: 0 auto;
}
.base {
  text-align: justify;
  margin: 0 auto;
  width: 90%;
}
.botones {
  text-align: right;
  margin-right: 5%;
}
a {
  color: #4590bf;
  margin-bottom: 5%;
  margin-top: 5%;
  display: inline-block;
}
.boton {
  background-color: #4590bf;
  color: white;
  border: 1px solid #4590bf;
  margin-right: auto;
  border-radius: 5px;
  padding: 8px 25px;
  font-size: 16px;
  margin-bottom: 20px;
  width: 30%;
}
.boton:hover {
  background-color: #4590bf;
  color: white;
  border: 1px solid #4590bf;
  font-weight: 600;
  box-shadow: 1px 1px 10px #4590bf;
}
.boton2 {
  color: #4590bf;
  border: 1px solid #4590bf;
  background-color: white;
  margin-right: auto;
  border-radius: 5px;
  padding: 7px 25px;
  font-size: 16px;
  margin-bottom: 20px;
  margin-right: 10px;
  width: 30%;
}
.boton2:hover {
  color: #4590bf;
  border: 1px solid #4590bf;
  background-color: white;
  box-shadow: 1px 1px 10px #4590bf;
  font-weight: 600;
}
.imagen img {
  width: 70%;
  height: auto;
  margin-left: 20px;
}
.icon.is-left {
  width: 10px !important;
}
@media screen and (max-width: 680px) {
  .boton,
  .boton2 {
    width: 45%;
    font-size: 75%;
    padding: 6px 15px;
  }
  h2 {
    font-size: 102%;
  }
  h2::after {
    width: 40%;
  }
  .logueo {
    width: 100%;
    margin: 0 auto;
  }
  .login {
    margin: 20px;
  }
  a {
    font-size: 85%;
  }
}
</style>