<template>
  <div>
    <div class="fondo">
      <div class="container">
        <div class="grupo columns is-desktop">
          <div class="foto column is-5">
            <img
              class="image is-256x256"
              src="@/assets/img/equipo.png"
              alt="foto equipo"
            />
          </div>
          <div class="quien column-is-5">
            <h2>¿Quienes somos?</h2>
            <br />
            <p>
              CompuMACC surgió para satisfacer las necesidades que existen en
              pequeñas y medianas empresas, así como en profesionistas
              independientes, estudiantes y usuarios domésticos, brindándoles un
              soporte técnico personalizado que les dará una solución a cada una
              de sus necesidades...
            </p>
            <!--
            <p>
              Estamos comprometidos a efectuar con eficiencia y responsabilidad
              las ventas y servicios que usted nos ha encomendado, cumpliendo
              con los estándares acordados. Cumplir sus expectativas con una
              actitud de evolución continua hacia nuestros clientes
            </p>
            <p>
              Desde nuestra fundacion hemos mantenido la filosofia de la
              calidad, la promocion de nuestros productos, y la constante
              atencion a nuestros clientes, lo cual nos ha llevado a un sistema
              de produccion totalmente integrado, que garantiza la calidad total
              de cada uno de nuestros productos a los mejores precios.
            </p>
            -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InfoTeam",
};
</script>

<style scoped>
.fondo {
  background-color: #4590bf;
  padding: 10px;
}
.grupo {
  padding: 30px 10px 30px;
}
.foto {
  margin: 0 auto;
  text-align: center;
  margin-top: 0px;
}
.quien {
  padding: 15px;
}
h2 {
  color: white;
  font-size: 30px;
  text-align: justify;
  padding: 0px 20px;
}
p {
  color: white;
  text-align: justify;
  padding-bottom: 10px;
  padding: 0px 20px;
  font-size: 18px;
  margin-bottom: 10px;
}
@media screen and (max-width: 768px) {
  h2 {
    font-size: 20px;
  }
  p {
    font-size: 14px;
  }
  .foto {
    padding-top: 0px;
  }
  .foto .image {
    width: 50%;
    height: 70%;
    margin: 0 auto;
  }
}
</style>
