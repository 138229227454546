<template>
  <div class="inicio">
    <Banner />
    <div class="fondo">
      <div class="container">
        <p
          class="
            has-text-left
            is-size-4-mobile is-size-3-tablet is-size-3-desktop
          "
        >
          ¿Qué deseas comprar?
        </p>
        <div class="columns is-multiline is-mobile is-desktop">
          <div class="column is-3-desktop is-3-tablet is-6-mobile is-flex">
            <Card
              name="Cámaras de seguridad"
              :image="categories[0]"
              full="full"
              class="is-clickable"
            />
          </div>

          <div class="column is-3-desktop is-3-tablet is-6-mobile is-flex">
            <Card
              name="PC´s Armadas"
              :image="categories[1]"
              full="full"
              class="is-clickable"
            />
          </div>

          <div class="column is-3-desktop is-3-tablet is-6-mobile is-flex">
            <Card
              name="Dispositivos móviles"
              padding="mg-mob"
              :image="categories[2]"
              full="full"
              class="is-clickable"
            />
          </div>

          <div class="column is-3-desktop is-3-tablet is-6-mobile is-flex">
            <Card
              name="Accesorios"
              padding="mg-mob"
              :image="categories[3]"
              full="full"
              class="is-clickable"
            />
          </div>
        </div>
      </div>
    </div>
    <Info />
    <div class="container">
      <p
        class="
          has-text-left
          is-size-4-mobile is-size-3-tablet is-size-3-desktop
        "
      >
        Productos destacados
      </p>
    </div>
    <Featured />
    <BannerInfo />
    <div class="container prod-p is-mobile has-text-centered">
      <p
        class="
          has-text-left
          is-size-4-mobile is-size-3-tablet is-size-3-desktop
        "
      >
        Esto te puede interesar
      </p>
      <div class="columns is-multiline is-mobile n-p">
        <div
          class="column is-3-desktop is-3-tablet is-6-mobile"
          v-for="(item, key) of products"
          :key="key"
        >
          <Card
            :name="item.name"
            :image="item.image"
            :product="item"
            :promo="item.promo"
          />
        </div>
      </div>

      <br />
      <RouterLink to="/products/">
        <a>Ver todos los productos...</a>
      </RouterLink>
    </div>
    <Comments />
  </div>
</template>

<script>
import Banner from "@/components/products/BannerNotices.vue";
import BannerInfo from "@/components/home/BannerInfo.vue";
import Card from "@/components/Card.vue";
import Featured from "@/components/home/Featured.vue";
import Comments from "@/components/home/Comments.vue";
import Info from "@/components/home/Info.vue";
import LoadPage from "@/mixins/load.js";
import axios from "axios";

export default {
  name: "IndexPage",
  components: {
    Banner,
    Featured,
    BannerInfo,
    Comments,
    Card,
    Info,
  },
  data() {
    return {
      products: [],
      categories: [],
    };
  },
  methods: {
    async getCategories() {
      const category = await axios
        .get("https://rickandmortyapi.com/api/character/100,101,102,103")
        .catch(() => {
          this.danger();
        });
      if (category.status == 200) {
        category.data.forEach((element) => {
          this.categories.push(element.image);
        });
        console.log(category);
      }
    },
    async getProducts() {
      let data = Array.from(Array(8).keys()).map((number) => {
        return number + 200;
      });

      const product = await axios
        .get("https://rickandmortyapi.com/api/character/" + data)
        .catch(() => {
          this.danger();
        });
      this.products = product.status == 200 ? product.data : [];
      this.products.forEach((element) => {
        element.id % 5 == 0 ? (element.promo = true) : (element.promo = false);
      });
      console.log(this.products);
    },
    danger() {
      this.$buefy.notification.open({
        duration: 5000,
        message: `Oops! Algo ha salido mal! :(`,
        position: "is-bottom-right",
        type: "is-danger",
        hasIcon: true,
      });
    },
  },
  created() {
    this.getCategories();
    this.getProducts();
  },
  mixins: [LoadPage],
};
</script>

<style scoped>
.productos {
  margin-bottom: 10px;
}
.fondo {
  padding: 20px 0px;
}
p {
  color: #333;
  margin: 25px 0px;
  font-weight: 600;
  text-align: justify;
  padding: 0px 20px;
}
p:after {
  display: block;
  height: 3px;
  background-color: #f21313;
  content: "";
  width: 120px;
  margin-top: 10px;
  box-shadow: 1px 1px 3px #666;
  margin-bottom: 40px;
}
.n-p {
  margin-top: -30px;
}
</style>