<template>
  <div class="services">
    <Poster message="Servicios que ofrecemos" />
    <div class="services-item">
      <div class="columns is-desktop is-mobile is-tablet h-card">
        <div
          class="
            column
            text-blue
            is-flex
            is-justify-content-center
            is-align-items-center
            is-flex-direction-column
          "
          @mouseover="link"
        >
          <i class="fa-solid fa-shop icon-service"></i>
          <br />
          <p>Ventas</p>
        </div>
        <div
          class="
            column
            bg-blue
            has-text-white
            is-flex
            is-justify-content-center
            is-align-items-center
            is-flex-direction-column
          "
        >
          <i class="fa-solid fa-video icon-service"></i>
          <br />
          <p>Videovigilancia</p>
        </div>
      </div>
      <div class="columns is-desktop is-mobile is-tablet h-card">
        <div
          class="
            column
            bg-blue
            has-text-white
            is-flex
            is-justify-content-center
            is-align-items-center
            is-flex-direction-column
          "
        >
          <i class="fa-solid fa-laptop-medical icon-service"></i>
          <br />
          <p>Mantenimiento</p>
        </div>
        <div
          class="
            column
            text-blue
            is-flex
            is-justify-content-center
            is-align-items-center
            is-flex-direction-column
          "
        >
          <i class="fa-solid fa-wifi icon-service"></i>
          <br />
          <p>Redes</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Poster from "@/components/Poster.vue";

export default {
  name: "ServicesView",
  components: {
    Poster,
  },
  methods: {
    link() {
      alert("Hello");
    },
  },
};
</script>

<style scoped>
.services-item {
  margin-top: 11px;
}
.icon-service {
  font-size: 100px;
}
.bg-blue {
  background-color: #4590bf;
}
.text-blue {
  color: #4590bf;
}
.h-card {
  height: 300px;
}
.description {
  position: relative;
  top: 0px;
  width: 80%;
  background-color: red;
  margin-left: -155px;
  max-height: 300px;
  height: 300px;
}
</style>
